import React from 'react';
import styled from 'styled-components';
import All_counties from '../../../images/product-assets/all-counties.svg';
import Global_coverage from '../../../images/product-assets/global-coverage.svg';
import Million_users from '../../../images/product-assets/million-users.svg';

const Container = styled.section`
    background: #154683;
`;

const ContentBox = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 44px 20px;

    @media screen and (max-width: 500px) {
        padding: 30px 35px;
    }
`;

const Heading = styled.h1`
    color: #fff;
    font-size: 42px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;

    @media screen and (max-width: 500px) {
        font-size: 24px;
        line-height: 28.44px;
    }
`;

const GridBox = styled.div`
    margin-top: 56px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 42px;
    padding: 15px 0;

    @media screen and (max-width: 500px) {
        margin-top: 32px;
    }
`;

const CardHeading = styled.h2`
    margin-top: 14px;
    font-size: 30px;
    font-weight: 500;
    line-height: 46px;
    text-align: center;
    color: #fff;

    @media screen and (max-width: 500px) {
        font-size: 21px;
        line-height: 24.89px;
    }
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px;
`;

const CardIcon = styled.img`
    width: 87px;
    height: 87px;

    @media screen and (max-width: 500px) {
        width: 49px;
        height: 49px;
    }
`;

function Coverage() {
    return (
        <Container>
            <ContentBox>
                <Heading>Always adding coverage</Heading>
                <GridBox>
                    <Card>
                        <CardIcon src={All_counties} alt="Over 3,200 US Counties and Equivalents" />
                        <CardHeading>Over 3,200 US Counties and Equivalents</CardHeading>
                    </Card>
                    <Card>
                        <CardIcon
                            src={Global_coverage}
                            alt="60+ Sanction & Global Watch Lists Checked"
                        />
                        <CardHeading>60+ Sanction & Global Watch Lists Checked</CardHeading>
                    </Card>
                    <Card>
                        <CardIcon
                            src={Million_users}
                            alt="1.5 Billion Unique Identities and Counting"
                        />
                        <CardHeading>1.5 Billion Unique Identities and Counting</CardHeading>
                    </Card>
                </GridBox>
            </ContentBox>
        </Container>
    );
}

export default Coverage;
